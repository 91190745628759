import { FC, PropsWithChildren } from 'react';
import { makeStyles, Theme, Tooltip as MuiTooltip } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: 12,
        fontFamily: 'Ubuntu',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    span: {
        margin: 0,
        padding: 0,
        display: 'flex'
    }
}));

interface IProps {
    title: string;
    children: React.ReactElement<any, any> | null | React.ReactNode;
}

const Tooltip: FC<PropsWithChildren<IProps>> = ({ title, children }) => {
    const classes = useStyles();

    return (
        <MuiTooltip classes={{ tooltip: classes.tooltip }} title={title} interactive>
            <div className={classes.span}>{children}</div>
        </MuiTooltip>
    );
};

export default Tooltip;
