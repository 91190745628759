import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { EActivityType } from '../../../../../interfaces/enums/EActivityType';
import ActivityButton from '../../../../../ui/buttons/activity-button/ActivityButton';
import WindowModal from '../../../../../ui/modals/WindowModal';
import usePopup from '../../../../../hooks/usePopup';
import { usePostLaunchActivity } from '../../../../../services/SkillQueryService';
import { IActivity } from '../../../../../interfaces/IActivity';
import { IFullVideoUrlsResponse, createFullVideoUrls, createUrl } from '../../../../../utils/createUrl';
import VidstackVideo from '../../../../../ui/general/video/VidstackVideo';
import { isFullLink } from '../../../../../utils/isFullLink';
import { FooterBox, LastTriedSpan, RootBox, TitleSpan } from './Style';

interface IProps {
    id: string;
    title: string;
    description?: string;
    type: EActivityType;
    url: string;
    isPopupMode: boolean;
    lastLaunched?: string;
    icon?: string;
    index?: number;
    appUrl?: string;
    updateActivityLastTriedData: (activityId: string, lastTriedDate: Date) => void;
}

const Activity: FC<IProps> = ({
    id,
    title,
    type,
    url,
    isPopupMode,
    description,
    lastLaunched,
    icon,
    index,
    updateActivityLastTriedData,
    appUrl
}) => {
    const { mutateAsync } = usePostLaunchActivity();
    const [internalLastLaunched, setInternalLastLaunched] = useState<string | undefined>(lastLaunched);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const [fullVideoUrls, setFullVideoUrls] = useState<IFullVideoUrlsResponse | undefined>();

    useEffect(() => {
        const urls = createFullVideoUrls(url);
        setFullVideoUrls(urls);
    }, [url]);

    const activityButtonClickCallback = useCallback(() => {
        if (type === EActivityType.TRY_IT || type === EActivityType.LEARN) {
            mutateAsync({ activityId: id }).then((response: IActivity) => {
                setInternalLastLaunched(response.lastLaunched);
                updateActivityLastTriedData(id, new Date());
            });
            if (isPopupMode) {
                if (!isOpen) open();
                else {
                    alert("Can't open more dialogs");
                }
            } else {
                if (nextStepCardRef?.current) {
                    openNewWindow();
                }
            }
        } else if (type === EActivityType.WATCH) {
            mutateAsync({ activityId: id }).then((response: IActivity) => {
                setInternalLastLaunched(response.lastLaunched);
            });
            updateActivityLastTriedData(id, new Date());
            setVideoExpanded(true);
        }
    }, [url, type, id]);

    const nextStepCardRef = useRef<HTMLInputElement>(null);
    const { openNewWindow, isOpen, close, open } = usePopup(nextStepCardRef, createUrl(url)?.generatedUrl || '');
    const [isVideoExpanded, setVideoExpanded] = useState<boolean>(false);

    const windowMemo = useMemo(() => {
        return (
            <WindowModal
                title='First window'
                id='first'
                isOpen={isOpen}
                handleClose={close}
                url={createUrl(url)?.generatedUrl || ''}
                openNewWindowCallback={openNewWindow}
            />
        );
    }, [isOpen, close, openNewWindow]);

    const generatedContentByActivityTypeMemo = useMemo(() => {
        switch (type) {
            case EActivityType.TRY_IT:
            case EActivityType.LEARN:
                return (
                    <>
                        <TitleSpan ref={nextStepCardRef} variant={isMdDown ? 'subtitle2' : 'subtitle1'}>
                            {title}
                        </TitleSpan>
                        <FooterBox>
                            {internalLastLaunched && (
                                <LastTriedSpan>
                                    <span>Tried</span>
                                    <span>{format(new Date(internalLastLaunched), 'LLL dd, yyyy')}</span>
                                </LastTriedSpan>
                            )}
                            <ActivityButton clickHandler={activityButtonClickCallback} type={type} />
                        </FooterBox>
                    </>
                );
            case EActivityType.WATCH:
                return (
                    <>
                        <TitleSpan ref={nextStepCardRef} variant={isMdDown ? 'subtitle2' : 'subtitle1'}>
                            {title}
                        </TitleSpan>
                        <FooterBox>
                            {internalLastLaunched && (
                                <LastTriedSpan>
                                    <span>Watched</span>{' '}
                                    <span>{format(new Date(internalLastLaunched), 'LLL dd, yyyy')}</span>
                                </LastTriedSpan>
                            )}
                            <ActivityButton clickHandler={activityButtonClickCallback} type={type} />
                        </FooterBox>
                    </>
                );
            default:
                return <></>;
        }
    }, [type, internalLastLaunched]);

    const changeVideoOpenHandler = useCallback((isExpanded: boolean) => {
        setVideoExpanded(isExpanded);
    }, []);

    return (
        <RootBox id={`activity-card-${id}`} className={`activity-card-${index ?? 0}`}>
            {generatedContentByActivityTypeMemo}
            {isPopupMode && <>{windowMemo}</>}
            {isVideoExpanded && (
                <VidstackVideo
                    videoUrl={url && isFullLink(url) ? url : fullVideoUrls?.videoUrl || ''}
                    defaultOpen={isVideoExpanded}
                    onVideoOpenChange={changeVideoOpenHandler}
                    captionUrl={fullVideoUrls?.captionUrl}
                />
            )}
        </RootBox>
    );
};

export default Activity;
