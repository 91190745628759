import { Paper } from '@material-ui/core';
import { Typography } from '@mui/material';
import styled from '../../../ui/theme/styled';

export const SkillPaperCountSpan = styled(Typography)(({ theme }) => ({
    paddingLeft: 7,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center'
}));

export const SkillPaperTitleWrapper = styled('div')({
    display: 'flex',
    paddingBottom: 12
});

export const SkillPaperTitleSpan = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main
}));

export const RootInnerBox = styled(Typography)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: 1080,
    paddingLeft: 20,
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
        width: '100%'
    }
}));

export const SkillStepsWrapperPaper = styled(Paper)(({ theme }) => ({
    padding: 24,
    display: 'flex',
    gap: 16,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('lg')]: {
        padding: '12px 20px'
    },
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
    }
}));

export const SkillListWrapper = styled('div')({
    flex: 1
});

export const RootBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 8
});

export const NextStepsWrapper = styled('div')({
    flex: 1
});

export const NextStepsBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16
});

export const SkillStepsBox = styled('div')({
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08), 0px 0.5px 1.5px rgba(0, 0, 0, 0.08)'
});

export const SkillPaperTitle = styled('div')(({ theme }) => ({
    display: 'flex',
    fontFamily: 'Ubuntu',
    color: theme.palette.primary.main,
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    padding: '12px 18px 18px 18px',
    flexDirection: 'column'
}));

export const StepPaperTitleBox = styled('div')(({ theme }) => ({
    fontFamily: 'Ubuntu',
    color: theme.palette.primary.main,
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    padding: '12px 18px 18px 0'
}));

export const TabTitle = styled('span')<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    fontFamily: 'Ubuntu',
    fontSize: '20px',
    lineHeight: '24px',
    color: isSelected ? theme.palette.primary.main : theme.palette.gray.light,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        letterSpacing: '0.1px'
    }
}));

export const SkillTabCount = styled('span')<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.25px',
    color: isSelected ? theme.palette.primary.main : theme.palette.gray.light,
    fontWeight: 400,
    paddingLeft: '6px',
    [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        letterSpacing: '0.1px'
    }
}));

export const TabsWrapper = styled('div')({
    display: 'flex'
});
