import { makeStyles } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles({
    page: {
        maxWidth: '100%',
        paddingLeft: '20px',
        paddingRight: '20px'
    }
});

const Page: FC<React.PropsWithChildren> = ({ children }) => {
    const classes = useStyles();
    return <div className={classes.page}>{children}</div>;
};

export default Page;
