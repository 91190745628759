import { FC, MouseEvent, ReactElement } from 'react';
import { Dialog as MuiDialog, DialogContent, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { PrimaryButton, SecondaryButton } from 'nulia-ui';
import { Colors } from '../../Colors';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseDialogButton from '../../components/buttons/CloseDialogButton';

const useStyles = makeStyles({
    dialogContent: {
        paddingTop: 32,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24
    },
    dialogTitle: {
        paddingTop: 32,
        paddingLeft: 24,
        paddingRight: 24,
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '32px'
    },
    dialogText: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px'
    },
    dialogButtonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 32
    },
    buttons: {
        paddingBottom: 32,
        paddingTop: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    oneButton: {
        justifyContent: 'flex-end'
    },
    content: {
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 0,
        paddingTop: 16,
        overflowY: 'inherit'
    },
    paper: {
        minWidth: 300,
        minHeight: 300,
        maxWidth: 1000,
        boxShadow: '0 12.5px 128px 4px rgba(0,0,0,0.08), 0 24px 32px 2px rgba(0,0,0,0.08)',
        overflowY: 'inherit'
    },
    contentAndText: {
        marginTop: 16
    },
    backdrop: {
        backgroundColor: 'transparent'
    },
    info: {
        borderBottom: '1px solid rgba(0,0,0,0.08)',
        width: '100%',
        paddingTop: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingBottom: 8
    },
    customerName: {
        color: Colors.textSecondary,
        fontFamily: 'Ubuntu',
        fontSize: 14,
        letterSpacing: 0.1,
        lineHeight: '24px'
    },
    o365Counts: {
        color: Colors.textTertiary,
        fontFamily: 'Open Sans',
        fontSize: 12,
        letterSpacing: 0.5,
        lineHeight: '20px'
    }
});

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept?: (event: MouseEvent) => void;
    title?: string | ReactElement;
    acceptText?: string;
    cancelText?: string;
    text?: string;
    content?: ReactElement;
    width?: number;
    acceptButtonWidth?: number;
    hideClose?: boolean;
    isSubmitActionDisabled?: boolean;
    isBackdropCloseActive?: boolean;
    height?: number;
}

const Dialog: FC<Props> = ({
    open,
    onClose,
    onAccept,
    title,
    text,
    content,
    cancelText,
    acceptText,
    width,
    acceptButtonWidth,
    hideClose = false,
    isSubmitActionDisabled,
    isBackdropCloseActive = false,
    height
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <MuiDialog
            open={open}
            onClose={isBackdropCloseActive ? onClose : undefined}
            classes={{
                paper: classes.paper
            }}
            BackdropProps={{
                classes: {
                    root: classes.backdrop
                }
            }}
            fullScreen={isMobile}
        >
            {!hideClose && (
                <CloseDialogButton onClose={onClose} style={{ position: 'absolute', top: 16, right: 16, zIndex: 10 }} />
            )}
            {title && <div className={classes.dialogTitle}>{title}</div>}
            <DialogContent
                className={classes.content}
                style={{
                    width: width ? width : 400,
                    display: 'flex',
                    flexDirection: 'column',
                    height: height ? height : 400
                }}
            >
                {content && content}
                {text && <div className={clsx(classes.dialogText, classes.contentAndText)}>{text}</div>}
                {(cancelText || acceptText) && (
                    <div className={clsx(classes.buttons, !cancelText ? classes.oneButton : undefined)}>
                        {cancelText && (
                            <SecondaryButton onClick={onClose} style={{ width: 112 }} title={cancelText}>
                                {cancelText}
                            </SecondaryButton>
                        )}
                        {acceptText && onAccept && (
                            <PrimaryButton
                                clickHandler={onAccept}
                                style={!acceptButtonWidth ? { width: 112 } : { width: acceptButtonWidth }}
                                disabled={isSubmitActionDisabled}
                                title={acceptText}
                            ></PrimaryButton>
                        )}
                    </div>
                )}
            </DialogContent>
        </MuiDialog>
    );
};

export default Dialog;
