import { styled } from '@mui/system';

export const CustomersBox = styled('div')(({ theme }) => ({
    marginTop: 16,
    minWidth: '100%'
}));

export const CardBox = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: 4,
    boxShadow: '0 0.5px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0.5px rgba(0, 0, 0, 0.08)',
    backgroundColor: '#ffffff',
    paddingTop: 24
}));
