import { RefObject, useCallback, useRef } from 'react';
import useModal from './useModal';

interface IUsePopup {
    openNewWindow: () => void;
    isOpen: boolean;
    close: () => void;
    open: () => void;
}

const usePopup: (cardRef: RefObject<HTMLElement>, url: string) => IUsePopup = (cardRef, url) => {
    const checkWindowPositionRef = useRef<NodeJS.Timeout | null>(null);
    const { isOpen, open, close } = useModal({ defaultOpen: false });
    const openNewWindow = useCallback(() => {
        if (cardRef?.current) {
            // const { x, y, width, height } = cardRef?.current?.getBoundingClientRect();
            const windowModalPositionString = localStorage.getItem('windowModalPosition');
            let windowModalPosition = {
                x: 100,
                y: 100,
                width: 900,
                height: 650
            };
            if (windowModalPositionString) {
                const {
                    x: lastX,
                    y: lastY,
                    width: lastWidth,
                    height: lastHeight
                } = JSON.parse(windowModalPositionString);
                if (lastX) windowModalPosition.x = lastX;
                if (lastY) windowModalPosition.y = lastY;
                if (lastWidth) windowModalPosition.width = lastWidth;
                if (lastHeight) windowModalPosition.height = lastHeight;
            }
            const newWindowOpen = window.open(
                // 'http://localhost:3000',
                url,
                '',
                // 'https://white-water-0f8a3640f.1.azurestaticapps.net/',
                // 'https://nuliaworks.com//',
                `popup=1,width=${windowModalPosition.width - 17},height=${
                    windowModalPosition.height
                },position=absolute,left=${windowModalPosition.x},top=${windowModalPosition.y}`
            );
            if (newWindowOpen) {
                let oldX = newWindowOpen.screenX;
                let oldY = newWindowOpen.screenY;
                let oldWidth = newWindowOpen.window.innerWidth;
                let oldHeight = newWindowOpen.window.innerHeight;
                var interval = setInterval(function () {
                    try {
                        const newScreenX = newWindowOpen.screenX;
                        const newScreenY = newWindowOpen.screenY;
                        const newScreenWidth = newWindowOpen.window.innerWidth;
                        const newScreenHeight = newWindowOpen.window.innerHeight;
                        if (
                            oldX !== newScreenX ||
                            oldY !== newScreenY ||
                            newScreenWidth !== oldWidth ||
                            newScreenHeight !== oldHeight
                        ) {
                            if (newWindowOpen.screenX !== 0 && newScreenY !== 0) {
                                localStorage.setItem(
                                    'windowModalPosition',
                                    JSON.stringify({
                                        x: newScreenX,
                                        y: newScreenY,
                                        width: newScreenWidth,
                                        height: newScreenHeight
                                    })
                                );
                            }
                        }

                        oldX = newScreenX;
                        oldY = newScreenY;
                        oldWidth = newScreenWidth;
                        oldHeight = newScreenHeight;
                    } catch (e) {}
                }, 500);
                checkWindowPositionRef.current = interval;
            }
        }
    }, [url]);

    return {
        openNewWindow,
        isOpen,
        close,
        open
    };
};

export default usePopup;
