import { FC, useState } from 'react';
import { Popover } from '@material-ui/core';
import { useMsal } from '@azure/msal-react';
import DefaultUserImage from '../default-user-image/DefaultUserImage';
import TextButton from '../../buttons/text-button/TextButton';
import { useUserAuthStateValue } from '../../../contexts/UserAuthContext';
import { UserInfo, StyledAvatar, StyledUserControl, useStyles } from './Style';

interface IProps {
    clientType?: string;
}

const textButtonStyle = {
    width: '100%',
    justifyContent: 'flex-start',
    fontSize: '14px',
    padding: '6px 8px',
    marginBottom: '16px',
    backgroundColor: 'transparent !important',
    color: '#666666'
};

const UserHeaderControl: FC<IProps> = ({ clientType }) => {
    const classes = useStyles();
    const [openPopover, setOpenPopover] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const msalInstance = useMsal();
    const { userImage } = useUserAuthStateValue();

    const toggleOpenPopover = () => {
        setOpenPopover(!openPopover);
    };

    const onOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        toggleOpenPopover();
    };

    const logOut = () => {
        msalInstance.instance.logout();
    };

    const terms = () => {
        window.open('https://nulia.com/terms', '_blank');
    };

    const privacy = () => {
        window.open('https://nulia.com/privacy', '_blank');
    };

    const id = openPopover ? 'user-info-popover' : undefined;

    return (
        <>
            <UserInfo isActive={openPopover} tabIndex={0} aria-describedby={id} onClick={onOpenPopover}>
                {userImage && userImage?.data && userImage?.data.trim() !== '' ? (
                    <StyledUserControl>
                        <StyledAvatar
                            isActive={openPopover}
                            alt='User'
                            src={`data:image/png;base64,${userImage.data}`}
                        />
                    </StyledUserControl>
                ) : (
                    <DefaultUserImage size={28} />
                )}
            </UserInfo>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={toggleOpenPopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                classes={{
                    paper: classes.popover
                }}
            >
                <TextButton variant='purple' onClick={terms} style={textButtonStyle}>
                    TERMS AND CONDITIONS
                </TextButton>
                <TextButton variant='purple' onClick={privacy} style={textButtonStyle}>
                    PRIVACY POLICY
                </TextButton>
                <TextButton variant='purple' onClick={logOut} style={{ ...textButtonStyle, marginBottom: 'unset' }}>
                    LOGOUT
                </TextButton>
            </Popover>
        </>
    );
};

export default UserHeaderControl;
