import { EAchievementState } from '../interfaces/enums/EAchievementState';
import { getAssertionIdFromBadgeUrl } from '../utils/getAssertionIdFormBadgeUrl';

const routes = {
    ADMIN: '/admin',
    ADMIN_LICENSES: '/admin/licenses',
    ADMIN_ROLES: '/admin/roles',
    ADMIN_HISTORY: '/admin/history',
    ADMIN_HISTORY_DETAILS: '/admin/history/details/:id',
    LOGIN: '/login',
    OUTCOMES: '/outcomes',
    ADMIN_OUTCOMES: '/outcomes/admin',
    HISTORY_OUTCOMES: '/outcomes/history',
    USER_OUTCOMES: '/outcomes/user',
    TAB_OUTCOMES: '/tab/outcomes',
    OUTCOME: '/outcome/:id',
    OUTCOME_BADGE: '/outcome/:id/badge/:badgeId',
    SETTINGS: '/settings',
    SETTINGS_PERSONAL: '/settings/personal',
    SETTINGS_ORGANIZATIONAL: '/settings/organization',
    SKILLS: '/skills',
    SKILL: '/skill/:id',
    HOME: '/home',
    FEEDBACK: '/feedback',
    INSIGHTS: '/insights',
    INSIGHTS_PERSONAL: '/insights/personal',
    INSIGHTS_ORGANIZATIONAL: '/insights/organization',
    INSIGHTS_PERSONAL_SKILLS: '/insights/personal/skills',
    INSIGHTS_PERSONAL_OUTCOMES: '/insights/personal/outcomes',
    INSIGHTS_PERSONAL_ENGAGEMENT: '/insights/personal/engagement',
    INSIGHTS_PERSONAL_BADGES: '/insights/personal/badges',
    INSIGHTS_ORGANIZATIONAL_USERS: '/insights/organization/users',
    INSIGHTS_ORGANIZATIONAL_OUTCOMES: '/insights/organization/outcomes',
    INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS: '/insights/organization/outcomes/:id',
    INSIGHTS_ORGANIZATIONAL_ENGAGEMENT: '/insights/organization/engagement',
    INSIGHTS_ORGANIZATIONAL_BADGES: '/insights/organization/badges',
    INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE: '/insights/organization/badges/:outcomeId/level/:badgeLevel',
    INSIGHTS_ORGANIZATIONAL_ASSESSMENT: '/insights/organization/assessment',
    INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS: '/insights/organization/assessment/:id',
    INSIGHTS_ORGANIZATIONAL_USER_SKILLS: '/insights/personal/skills/:id',
    INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES: '/insights/personal/outcomes/:id',
    INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT: '/insights/personal/engagement/:id',
    INSIGHTS_ORGANIZATIONAL_USER_BADGES: '/insights/personal/badges/:id',
    INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS: '/insights/organization/outcome/:outcomeId/skill/:skillId',
    LINKEDIN: '/linkedin',
    HOW_TOS_HOMEPAGE: '/how-to',
    HOW_TO: '/how-to/:id',
    HOW_TO_PLAYLIST: '/how-to/category/:categoryId/playlist/:playlistId',
    INSTRUCTIONS: '/instructions/:assertionId',
    SHOWCASE_BASE: '/showcase',
    SHOWCASE_HOME: '/showcase/home',
    // ACHIEVEMENTS_PREVIEW: '/achievements',
    ACHIEVEMENTS_ALL_BY_STATE: '/showcase/achievements/:showcaseAchievementState',
    ACHIEVEMENT_DETAIL: '/showcase/achievements/group/:id',
    BADGES_ALL_BY_CATEGORY: '/showcase/badges/:category',
    SOMETHING_WENT_WRONG: '/something-went-wrong',
    PAGE_FORBIDDEN: '/forbidden',
    NULIA_LICENSE_STATUS: '/nulia-license-status',
    PARTNER_ACCOUNTS: '/partner/accounts',
    PARTNER_ACCOUNT_DETAILS: '/partner/account/details/:id',
    PARTNER_PARTNERS: '/partner/partners',
    PARTNER_RESOURCES: '/partner/resources',
    PARTNER_CONTACT: '/partner/contact'
};

interface ISkillParams {
    behaviorId?: number;
    activityId?: number;
}

export const makeAccountDetailsRoute = (accountId: string | number) => {
    let generatedRoute = routes.PARTNER_ACCOUNT_DETAILS.replace(':id', accountId.toString());
    return generatedRoute;
};

const makeSkillRoute = (skillId: string | number, params?: ISkillParams) => {
    let generatedRoute = routes.SKILL.replace(':id', skillId.toString());
    if (params?.behaviorId) generatedRoute = generatedRoute.concat(`?behavior=${params.behaviorId}`);
    if (params?.activityId) generatedRoute = generatedRoute.concat(`?activity=${params.activityId}`);
    return generatedRoute;
};

const makeOutcomeRoute = (outcomeId: string | number) => {
    return routes.OUTCOME.replace(':id', outcomeId.toString());
};

const makeBadgeSharingInstructionRoute: (userBadgeUrl: string) => string = (userBadgeUrl) => {
    return routes.INSTRUCTIONS.replace(':assertionId', getAssertionIdFromBadgeUrl(userBadgeUrl));
};

const makeInsightsOutcomeDetailsRoute = (outcomeId: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_OUTCOMES_DETAILS.replace(':id', outcomeId.toString());
};

const makeInsightsAssessmentDetailsRoute = (id: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_ASSESSMENT_DETAILS.replace(':id', id.toString());
};

const makeInsightsOrganizationalUsersSkill = (id: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_USER_SKILLS.replace(':id', id.toString());
};

const makeInsightsOrganizationalUsersOutcome = (id: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_USER_OUTCOMES.replace(':id', id.toString());
};

const makeInsightsOrganizationalUsersEngagement = (id: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_USER_ENGAGEMENT.replace(':id', id.toString());
};

const makeInsightsOrganizationalUsersBadges = (id: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_USER_BADGES.replace(':id', id.toString());
};

const makeInsightsOrganizationalSingleBadge = (outcomeId: string | number, level: string) => {
    let levelNumber: number = 2;
    if (level === 'Master') levelNumber = 3;
    return routes.INSIGHTS_ORGANIZATIONAL_SINGLE_BADGE.replace(':outcomeId', outcomeId.toString()).replace(
        ':badgeLevel',
        levelNumber.toString()
    );
};

const makeInsightsSkillDetailsRoute = (outcomeId: string | number, skillId: string | number) => {
    return routes.INSIGHTS_ORGANIZATIONAL_SKILL_DETAILS.replace(':outcomeId', outcomeId.toString()).replace(
        ':skillId',
        skillId.toString()
    );
};

const makeHowToRoute = (id: string | number) => {
    return routes.HOW_TO.replace(':id', id.toString());
};

const makeHowToPlaylistRoute = (categoryId: string, playlistId: string) => {
    return routes.HOW_TO_PLAYLIST.replace(':categoryId', categoryId).replace(':playlistId', playlistId);
};

const makeAchievementDetailsRoute = (achievementId: string) => {
    return routes.ACHIEVEMENT_DETAIL.replace(':id', achievementId);
};

const makeBadgesAllByCategoryRoute = (categoryName: string) => {
    return routes.BADGES_ALL_BY_CATEGORY.replace(':category', categoryName);
};

const makeAchievementsAllByCategoryRoute = (achievementState: EAchievementState) => {
    let stateName = '';
    switch (achievementState) {
        case EAchievementState.AWARDED:
            stateName = 'awarded';
            break;
        case EAchievementState.IN_PROGRESS:
            stateName = 'in-progress';
            break;
        case EAchievementState.NOT_STARTED:
            stateName = 'not-started';
    }
    return routes.ACHIEVEMENTS_ALL_BY_STATE.replace(':showcaseAchievementState', stateName);
};

export {
    routes,
    makeSkillRoute,
    makeOutcomeRoute,
    makeBadgeSharingInstructionRoute,
    makeInsightsOutcomeDetailsRoute,
    makeInsightsAssessmentDetailsRoute,
    makeInsightsOrganizationalUsersSkill,
    makeInsightsOrganizationalUsersOutcome,
    makeInsightsOrganizationalUsersEngagement,
    makeInsightsOrganizationalUsersBadges,
    makeInsightsSkillDetailsRoute,
    makeInsightsOrganizationalSingleBadge,
    makeHowToRoute,
    makeHowToPlaylistRoute,
    makeAchievementDetailsRoute,
    makeBadgesAllByCategoryRoute,
    makeAchievementsAllByCategoryRoute
};
