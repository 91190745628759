import { Button } from '@material-ui/core';
import { styled, useTheme } from '@mui/system';
import { useNavigationStateValue } from '../../../contexts/NavigationContext';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';

interface IProps {
    id?: string;
    title: string;
    icon: JSX.Element;
    paths: string[];
    primaryPath: string;
    primaryColor?: string;
    activeBackgroundColor?: string;
    fullPathSelectedMode?: boolean;
}

const NavigationLinkItemContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

const Selector = styled('div')<{ color?: string }>(({ theme, color }) => ({
    borderRadius: 4,
    backgroundColor: color || theme.palette.primary.main,
    position: 'absolute',
    width: 4,
    height: 56,
    left: 106,
    zIndex: 999999,
    [theme.breakpoints.down('sm')]: {
        bottom: '-50px',
        left: 0,
        width: 100,
        height: 4,
        position: 'relative'
    }
}));

const StyledButton = styled(Button)<{ activeBackgroundColor?: string; isSelected?: boolean }>(
    ({ theme, activeBackgroundColor, isSelected }) => ({
        width: '100%',
        height: '100%',
        minHeight: 62,
        borderRadius: 4,
        fontSize: 14,
        letterSpacing: 1.25,
        fontWeight: 600,
        fontFamily: 'Ubuntu',
        lineHeight: '16px',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        backgroundColor: activeBackgroundColor && isSelected ? activeBackgroundColor : 'transparent',
        boxShadow: 'none',
        cursor: 'pointer',
        border: 0,
        outline: 0,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            color: theme.palette.text.primary
        },
        '&:focus': {
            backgroundColor: activeBackgroundColor && isSelected ? activeBackgroundColor : 'rgba(101,78,163,0.08)'
        },
        '&:active': {
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '54px'
        }
    })
);

const TitleSpan = styled('span')<{ primaryColor?: string }>(({ primaryColor }) => ({
    fontFamily: 'Ubuntu',
    fontWeight: 'bold',
    lineHeight: '20px',
    fontSize: 10,
    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    color: primaryColor
}));

const StyledButtonSpan = styled('span')({
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit'
});

const NavigationItemContent = styled('div', {
    shouldForwardProp: (prop) => prop !== 'isSelected'
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: isSelected ? theme.palette.primary.main : theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px'
    }
}));

const NavigationLinkItem: FC<IProps> = ({
    id,
    title,
    icon,
    paths,
    primaryPath,
    primaryColor,
    activeBackgroundColor,
    fullPathSelectedMode = false
}) => {
    const [selected, setSelected] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { setShowNav } = useNavigationStateValue();
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const { startNewCrumbs } = useCrumbsStateValue();

    useEffect(() => {
        if (fullPathSelectedMode) {
            // if all navigation links starts with same first URL part, e.g. /partner/:something:, /partner/:something:
            setSelected(
                paths.includes(location.pathname.split('/')[2]) ||
                    paths.find((path) => {
                        const basePath = path.split('/')[2];
                        return basePath === location.pathname.split('/')[2];
                    }) !== undefined
            );
        } else {
            // if all navigation link statrts with different first URL part, e.g. /skills/:something:, /outcomes/:something:
            setSelected(
                paths.includes(location.pathname.split('/')[1]) ||
                    paths.find((path) => {
                        const basePath = path.split('/')[1];
                        return basePath === location.pathname.split('/')[1];
                    }) !== undefined
            );
        }
    }, [paths, location.pathname, fullPathSelectedMode]);

    const openLink = () => {
        if (!isLgUp) {
            setShowNav(false);
        }
        startNewCrumbs(
            {
                name: title,
                pathname: primaryPath
            },
            true
        );
        navigate(primaryPath);
    };

    return (
        <NavigationLinkItemContainer key={id}>
            {selected && <Selector color={primaryColor} />}
            <StyledButton
                activeBackgroundColor={activeBackgroundColor}
                className={id}
                isSelected={selected}
                onClick={openLink}
                disableFocusRipple
            >
                <StyledButtonSpan>
                    <NavigationItemContent isSelected={selected}>
                        {icon}
                        <TitleSpan primaryColor={primaryColor}>{title}</TitleSpan>
                    </NavigationItemContent>
                </StyledButtonSpan>
            </StyledButton>
        </NavigationLinkItemContainer>
    );
};

export default NavigationLinkItem;
