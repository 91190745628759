// @ts-nocheck
import { FC, useEffect, useMemo, useState } from 'react';
import Draggable, { DraggableEvent, DraggableEventHandler } from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import { CloseOutlined, OpenInNewOutlined } from '@material-ui/icons';
import { ResizableBox } from 'react-resizable';
import { makeStyles } from '@material-ui/core';
import Tooltip from '../general/tooltip/Tooltip';
import { DialogActionBox, StyledDialog, StyledDialogContent, StyledDialogTitle, StyledIFrame } from './Style';

interface IProps {
    isOpen: boolean;
    handleClose: () => void;
    url: string;
    id: string;
    title: string;
    openNewWindowCallback?: () => void;
}

interface A extends PaperProps {
    x: number;
    y: number;
    setX: (x: number) => void;
    setY: (y: number) => void;
}

const useStyles = makeStyles({
    dialog: {
        cursor: 'move'
    },
    dialogContent: {
        '&:first-child': {
            padding: 10,
            background: 'white'
        }
    },
    clearIcon: {
        position: 'absolute',
        top: -20,
        right: -20,
        background: 'white',
        zIndex: 1,
        '&:hover': {
            background: 'white'
        }
    },
    paper: {
        overflowY: 'visible',
        maxWidth: 'none',
        maxHeight: 'none'
    },
    resizable: {
        position: 'relative',
        '& .react-resizable-handle': {
            position: 'absolute',
            width: 20,
            height: 20,
            bottom: 0,
            right: 0,
            background:
                "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
            backgroundPosition: 'bottom right',
            padding: 3,
            backgroundRepeat: 'no-repeat',
            backgroundOrigin: 'content-box',
            backgroundSize: 'cover',
            boxSizing: 'border-box',
            cursor: 'se-resize'
        }
    }
});

function saveModalPosition(id: string) {
    const dimensions = document.getElementById(`draggable-dialog-title${id}`)?.getBoundingClientRect();
    const dialogContainerElement = document.querySelector(`#draggable-dialog${id} > .MuiDialog-container`);
    if (dimensions && dialogContainerElement) {
        const { x, y } = dimensions;
        localStorage.setItem(
            'windowPopupPosition',
            JSON.stringify({
                x: x > 0 ? x : 0,
                y: y > 0 ? y : 0,
                width: dialogContainerElement.getBoundingClientRect().width,
                height: dialogContainerElement.getBoundingClientRect().height
            })
        );
    }
}

function PaperComponent(props: A) {
    return (
        <Draggable
            position={{ x: props.x, y: props.y }}
            cancel={'.react-resizable-handle'}
            onStart={(e) => {
                const targetId = (e.target as HTMLElement).localName;
                if (targetId === 'span') return false;
            }}
            onStop={(e: DraggableEvent) => {
                saveModalPosition(props.id);
            }}
            // @ts-expect-error: Let's ignore a compile error like this unreachable code
            onDrag={(e: DraggableEventHandler | undefined) => {
                if (e) {
                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                    if (e.movementX !== 0) {
                        // @ts-expect-error: Let's ignore a compile error like this unreachable code
                        props.setX((prev: number) => {
                            return prev + e.movementX;
                        });
                    }
                    // @ts-expect-error: Let's ignore a compile error like this unreachable code
                    if (e.movementY !== 0) {
                        // @ts-expect-error: Let's ignore a compile error like this unreachable code
                        props.setY((prev: number) => {
                            return prev + e.movementY;
                        });
                    }
                }
            }}
        >
            <Paper style={{ margin: 0, maxWidth: 'unset' }} {...props} />
        </Draggable>
    );
}

const WindowModal: FC<IProps> = ({ id, isOpen, handleClose, url, title, openNewWindowCallback }) => {
    const [x, setX] = useState<number>(0);
    const [y, setY] = useState<number>(0);
    const classes = useStyles();

    const [windowModalPosition, setWindowModalPosition] = useState<null | {
        x: number | string;
        y: number | string;
        width: number | string;
        height: number | string;
    }>(null);

    useEffect(() => {
        // TODO Probaby delete
        const escFunction = (event: Event) => {
            const targetId = (event.target as HTMLElement).id;
            if (!targetId || targetId === '' || targetId === ' ' || targetId === `draggable-dialog${id}`) {
                document.body.style.overflow = 'auto';
                document.body.style['padding-right'] = '0px';
            } else if (targetId === `draggable-dialog-content${id}` || targetId === `draggable-dialog-title${id}`) {
                document.body.style['padding-right'] = '0px';
            }
            return;
        };
        document.body.addEventListener('click', escFunction);
        return () => {
            document.body.removeEventListener('click', escFunction);
        };
    }, [id]);

    useEffect(() => {
        try {
            const modalPositionString = localStorage.getItem('windowPopupPosition');
            if (modalPositionString) {
                const modalPosition = JSON.parse(modalPositionString);
                if (modalPosition) {
                    setWindowModalPosition({
                        ...modalPosition,
                        x: parseInt(modalPosition?.x) > 0 ? modalPosition?.x : 0,
                        y: parseInt(modalPosition?.y) > 0 ? modalPosition?.y : 0
                    });
                }
            } else throw new Error();
        } catch (e) {
            setWindowModalPosition({
                x: 100,
                y: 100,
                width: 900,
                height: 650
            });
        }
    }, []);

    const resizableMemo = useMemo(() => {
        if (windowModalPosition)
            return (
                <ResizableBox
                    height={windowModalPosition?.height}
                    width={windowModalPosition?.width}
                    minConstraints={[100, 100]}
                    maxConstraints={[Infinity, Infinity]}
                    className={classes.resizable}
                    onResize={(e) => {
                        saveModalPosition(id);
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    <>
                        <StyledDialogTitle
                            id={`draggable-dialog-title${id}`}
                            style={{ cursor: 'move', display: 'flex', width: '100%', justifyContent: 'space-between' }}
                        >
                            {title}
                            <DialogActionBox>
                                <Tooltip title='Open in new window'>
                                    <OpenInNewOutlined
                                        onClick={() => {
                                            if (openNewWindowCallback) {
                                                openNewWindowCallback();
                                                handleClose();
                                            }
                                        }}
                                        style={{ zIndex: 2, cursor: 'pointer' }}
                                    />
                                </Tooltip>
                                <CloseOutlined style={{ zIndex: 2222, cursor: 'pointer' }} onClick={handleClose} />
                            </DialogActionBox>
                        </StyledDialogTitle>
                        <StyledDialogContent id={`draggable-dialog-content${id}`}>
                            <StyledIFrame src={url} title='description'></StyledIFrame>
                        </StyledDialogContent>
                    </>
                </ResizableBox>
            );
        return null;
    }, [windowModalPosition, title]);

    return (
        <>
            {/* {isOpen && <Backdrop open={isOpen} />} */}
            <StyledDialog
                x={windowModalPosition && parseInt(windowModalPosition?.x) > 0 ? windowModalPosition?.x + 'px' : 0}
                y={windowModalPosition && parseInt(windowModalPosition?.y) > 0 ? windowModalPosition?.y + 'px' : 0}
                PaperComponent={PaperComponent}
                PaperProps={{
                    id: id,
                    x: x,
                    y: y,
                    setX: setX,
                    setY: setY
                }}
                open={isOpen}
                aria-labelledby={`draggable-dialog-title${id}`}
                hideBackdrop
                style={{
                    width: 'fit-content',
                    height: 'fit-content'
                }}
                id={`draggable-dialog${id}`}
            >
                {resizableMemo}
            </StyledDialog>
        </>
    );
};

export default WindowModal;
