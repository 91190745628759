import { styled } from '@mui/material';
import { ArrowLeft, UserCheck, Book, Phone } from 'react-feather';
import { routes } from '../../pages/routes';
import { isUserAllowedToAccess, routeRules } from '../../pages/routeRules';
import { ERoute } from '../../interfaces/enums/ERoute';
import { useUserAuthStateValue } from '../../contexts/UserAuthContext';
import NavigationLinkItem from './navigation-link-item/NavigationLinkItem';

const NavigationLinksContainer = styled('div')(({ theme }) => ({
    '& button.navigation-back-button': {
        backgroundColor: theme.palette.primary.dark,
        position: 'absolute',
        height: '60px',
        width: '100%',
        bottom: 0
    }
}));

const navigationLinks = [
    {
        id: 'navigation-back-button',
        title: 'Back',
        icon: <ArrowLeft key='back-icon' color='#FFF' />,
        paths: [routes.HOME],
        routeRules: [ERoute.HOME],
        primaryPath: routes.HOME
    },
    {
        id: 'navigation-accounts-button',
        title: 'Accounts',
        icon: <Book key='accounts-icon' color='#FFF' />,
        paths: [routes.PARTNER_ACCOUNTS, routes.PARTNER_ACCOUNT_DETAILS],
        routeRules: [ERoute.PARTNER_ACCOUNTS, ERoute.PARTNER_ACCOUNT_DETAILS],
        primaryPath: routes.PARTNER_ACCOUNTS
    },
    {
        id: 'navigation-partners-button',
        title: 'Partners',
        icon: <UserCheck key='partners-icon' color='#FFF' />,
        paths: [routes.PARTNER_PARTNERS],
        routeRules: [ERoute.PARTNER_PARTNERS],
        primaryPath: routes.PARTNER_PARTNERS
    },
    {
        id: 'navigation-resources-button',
        title: 'Resources',
        icon: <Book key='resources-icon' color='#FFF' />,
        paths: [routes.PARTNER_RESOURCES],
        routeRules: [ERoute.PARTNER_RESOURCES],
        primaryPath: routes.PARTNER_RESOURCES
    },
    {
        id: 'navigation-contact-button',
        title: 'Contact',
        icon: <Phone key='contact-icon' color='#FFF' />,
        paths: [routes.PARTNER_CONTACT],
        routeRules: [ERoute.PARTNER_CONTACT],
        primaryPath: routes.PARTNER_CONTACT
    }
];

const PartnerPortalNavigationLinks = () => {
    const { currentUserData } = useUserAuthStateValue();
    return (
        <NavigationLinksContainer>
            {navigationLinks
                .filter((link) => {
                    let isAllowed = false;
                    link.routeRules?.forEach((routeRule: ERoute) => {
                        if (
                            routeRules[routeRule].rules.find((rule) => {
                                return currentUserData?.roles
                                    ? isUserAllowedToAccess(
                                          routeRule,
                                          currentUserData?.roles,
                                          currentUserData?.permissions
                                      )
                                    : false;
                                // if (isComplexRule(rule) && currentUserData?.roles) {
                                //     return isUserAllowedToAccess(
                                //         routeRule,
                                //         currentUserData?.roles,
                                //         currentUserData?.permissions
                                //     );
                                // } else {
                                //     return currentUserData?.roles.find((currentUserRole) => currentUserRole === rule);
                                // }
                            })
                        )
                            isAllowed = true;
                    });
                    return isAllowed;
                })
                .map((link) => {
                    const primaryRouteRule: ERoute | undefined = link.routeRules?.find((routeRule) => {
                        if (
                            routeRule &&
                            currentUserData &&
                            isUserAllowedToAccess(routeRule, currentUserData.roles, currentUserData.permissions)
                        ) {
                            return true;
                        }
                        return false;
                    });
                    return (
                        <NavigationLinkItem
                            id={link.id}
                            key={link.id}
                            title={link.title}
                            icon={link.icon}
                            primaryPath={primaryRouteRule ? routeRules[primaryRouteRule].route : ''}
                            paths={link.paths}
                            primaryColor='#FFF'
                            activeBackgroundColor='#826ac4'
                            fullPathSelectedMode
                        ></NavigationLinkItem>
                    );
                })}
        </NavigationLinksContainer>
    );
};

export default PartnerPortalNavigationLinks;
