import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import {
    MotdCardRoot,
    TitleBox,
    TitleTypography,
    ContentBox,
    ImageBox,
    DetailsBox,
    DetailsTitleTypography,
    DetailsDescriptionTypography,
    DetailsImg,
    ActionButtonBox
} from './Style';
import SecondaryButton from '../../buttons/secondary-button/SecondaryButton';
import PrevNextItem from '../../../ui/general/prev-next-item/PrevNextItem';
import { IMotwVM } from '../../../interfaces/views/IMotwVM';
import NoDataCard from '../no-data-card/NoDataCard';
import { createFullVideoUrls, createUrl, ICreateUrlResponse, IFullVideoUrlsResponse } from '../../../utils/createUrl';
import { EMotwActionType } from '../../../interfaces/enums/EMotwActionType';
import WindowModal from '../../../ui/modals/WindowModal';
import usePopup from '../../../hooks/usePopup';
import { usePostMotwLaunched } from '../../../services/MotwQueryService';
import fetchImageErrorCallback from '../../../utils/fetchImageErrorCallback';
import loadImageGraduallyHandler from '../../../utils/loadImageGradually';
import SomethingWentWrongCard from '../something-went-wrong-card/SomethingWentWrongCard';
import VidstackVideo from '../../../ui/general/video/VidstackVideo';
import { useNavigate } from 'react-router-dom';
import { ICrumb } from '../../../interfaces/ICrumb';
import { useCrumbsStateValue } from '../../../contexts/CrumbsContext';
interface IProps {
    name?: string;
    motdItems?: IMotwVM[];
    isLoading: boolean;
    isError: boolean;
    refetchCallback: () => void;
}

export interface IMotd {
    title: string;
    description: string;
}

const MotdCard: FC<IProps> = ({ name, motdItems, isLoading, isError, refetchCallback }) => {
    const [visibleIndex, setVisibleIndex] = useState<number>(0);
    const windowBaseRef = useRef<HTMLInputElement>(null);
    const { openNewWindow, isOpen, close } = usePopup(
        windowBaseRef,
        motdItems ? createUrl(motdItems[visibleIndex]!.actionUrl || '')?.generatedUrl || '' : ''
    );
    const { mutateAsync: mutatePostLaunchMotwAsync } = usePostMotwLaunched();
    const [fullVideoUrls, setFullVideoUrls] = useState<IFullVideoUrlsResponse | undefined>();
    const navigate = useNavigate();
    const { startNewCrumbs } = useCrumbsStateValue();

    const changeVisibleMotd = useCallback((isNext: boolean) => {
        if (isNext) {
            setVisibleIndex((visibleIndex) => visibleIndex + 1);
        } else {
            setVisibleIndex((visibleIndex) => visibleIndex - 1);
        }
    }, []);

    const actionClickHandler = useCallback(() => {
        if (
            motdItems &&
            visibleIndex >= 0 &&
            motdItems[visibleIndex].actionUrl !== null &&
            motdItems[visibleIndex].actionUrl !== undefined
        ) {
            const visibleMotwItem = motdItems[visibleIndex];
            const formattedUrl = createUrl(visibleMotwItem.actionUrl!);

            const makeCrumb: (motw: IMotwVM, urlInfo: ICreateUrlResponse) => ICrumb | undefined = (motw, urlInfo) => {
                switch (motw.actionUrl) {
                    case 'nulia-works://skills':
                        return {
                            name: 'Skills',
                            pathname: urlInfo.relativeUrl,
                            isDirectLink: true
                        } as ICrumb;
                    case 'nulia-works://showcase/home':
                        return {
                            name: 'Showcase',
                            pathname: urlInfo.relativeUrl,
                            isDirectLink: true
                        } as ICrumb;
                    case 'nulia-works://how-to':
                        return {
                            name: "How To's",
                            pathname: urlInfo.relativeUrl,
                            isDirectLink: true
                        } as ICrumb;
                    default:
                        return undefined;
                }
            };

            switch (visibleMotwItem.displayType) {
                case EMotwActionType.LINK:
                    const urlInfo = createUrl(visibleMotwItem.actionUrl);
                    if (urlInfo) {
                        const crumb = makeCrumb(visibleMotwItem, urlInfo);
                        if (crumb) startNewCrumbs(crumb, true);
                        if (urlInfo?.relativeUrl) navigate(urlInfo.relativeUrl);
                        else window.open(createUrl(visibleMotwItem.actionUrl)?.generatedUrl!, '_self');
                    }
                    break;
                case EMotwActionType.VIDEO:
                    if (visibleMotwItem.actionUrl) {
                        const urls = createFullVideoUrls(visibleMotwItem.actionUrl);
                        setFullVideoUrls(urls);
                    }
                    break;
                case EMotwActionType.POPUP:
                    openNewWindow();
                    mutatePostLaunchMotwAsync({ motwId: `${motdItems[visibleIndex].id}` });
                    break;
                case EMotwActionType.TAB:
                    if (formattedUrl) {
                        window.open(formattedUrl.generatedUrl, '_blank');
                        mutatePostLaunchMotwAsync({ motwId: `${motdItems[visibleIndex].id}` });
                    }
                    break;
            }
        }
    }, [motdItems, visibleIndex]);

    useEffect(() => {
        if (motdItems) setVisibleIndex(motdItems.length - 1);
    }, [motdItems]);

    const windowMemo = useMemo(() => {
        if (motdItems && motdItems[visibleIndex].actionUrl)
            return (
                <WindowModal
                    title='First window'
                    id='first'
                    isOpen={isOpen}
                    handleClose={close}
                    url={createUrl(motdItems[visibleIndex].actionUrl!)?.generatedUrl || ''}
                    openNewWindowCallback={openNewWindow}
                />
            );
        return <></>;
    }, [isOpen, close, openNewWindow, motdItems, visibleIndex]);

    const contentMemo = useMemo(() => {
        if (motdItems && motdItems.length === 0) {
            return <NoDataCard message='Currently no MOTWs found' />;
        }
        if (isError) {
            return (
                <SomethingWentWrongCard
                    boxStyle={{ boxShadow: 'none', minHeight: '258px' }}
                    actionCallback={refetchCallback}
                />
            );
        }
        return (
            <>
                <ImageBox>
                    {!motdItems || isLoading ? (
                        <Skeleton variant='rectangular' width='299px' height='160px' />
                    ) : (
                        <DetailsImg
                            src={createUrl(motdItems[visibleIndex].image)?.generatedUrl}
                            onError={fetchImageErrorCallback}
                            onLoad={loadImageGraduallyHandler}
                            alt={
                                visibleIndex >= 0 && motdItems
                                    ? motdItems[visibleIndex]?.imageAltTag
                                    : 'Message Of The Day'
                            }
                        />
                    )}
                </ImageBox>
                <DetailsBox>
                    <DetailsTitleTypography variant='subtitle1'>
                        {!motdItems || isLoading ? <Skeleton /> : motdItems[visibleIndex].title}
                    </DetailsTitleTypography>
                    <DetailsDescriptionTypography>
                        {!motdItems || isLoading ? (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        ) : (
                            <>{motdItems[visibleIndex].description}</>
                        )}
                    </DetailsDescriptionTypography>
                    <ActionButtonBox>
                        {motdItems && !isLoading && motdItems[visibleIndex].actionUrl && (
                            <SecondaryButton
                                onClick={actionClickHandler}
                                disabled={!motdItems}
                                style={{ minWidth: 180 }}
                            >
                                {motdItems && !isLoading ? motdItems[visibleIndex].actionName : 'Try it'}
                            </SecondaryButton>
                        )}
                    </ActionButtonBox>
                </DetailsBox>
            </>
        );
    }, [isError, isLoading, refetchCallback, motdItems, visibleIndex]);

    const onCloseMotwVideoCallback = useCallback(() => {
        setFullVideoUrls(undefined);
    }, []);

    return (
        <MotdCardRoot>
            {windowMemo}
            <TitleBox ref={windowBaseRef}>
                {name ? (
                    <TitleTypography variant='subtitle1'>{`Welcome Back, ${name}!`}</TitleTypography>
                ) : (
                    <Skeleton width='300px' />
                )}
                {motdItems && motdItems.length > 1 && (
                    <PrevNextItem
                        isPreviousDisabled={!motdItems || visibleIndex === 0}
                        isNextDisabled={!motdItems || visibleIndex === motdItems?.length - 1}
                        changeHandler={changeVisibleMotd}
                        infoJsx={motdItems ? `${visibleIndex + 1} / ${motdItems.length}` : ''}
                    />
                )}
            </TitleBox>
            <ContentBox oneColumn={(motdItems && motdItems.length === 0) || isError}>{contentMemo}</ContentBox>
            {fullVideoUrls && (
                <VidstackVideo
                    autoPlay
                    onCloseVideoCallback={onCloseMotwVideoCallback}
                    defaultOpen={true}
                    videoUrl={fullVideoUrls?.videoUrl}
                    chaptersUrl={fullVideoUrls?.chaptersUrl}
                    captionUrl={fullVideoUrls?.captionUrl}
                />
            )}
        </MotdCardRoot>
    );
};

export default MotdCard;
