import { FC, useEffect, useMemo, useRef } from 'react';
import FilterCategory from '../filter-category/FilterCategory';
import { useTranslation } from 'react-i18next';
import { useFiltersStateValue } from '../../../contexts/FiltersContext';
import SimpleCheckboxFilter from '../simple-checkbox-filter/SimpleCheckboxFilter';
import {
    RootBox,
    StyledFieldset,
    TitleBox,
    TitleSpan,
    FilterDropdownCategoryBox,
    StyledFormControl,
    MenuItemTitleSpan,
    StyledMenuItem
} from './Style';
import FilterDropdown from '../filter-dropdown-category/FilterDropdown';
import { StyledSelect } from '../../../ui/general/dropdown/Style';
import { Checkbox, Input, SelectChangeEvent } from '@mui/material';
import TextButton from '../../../ui/buttons/text-button/TextButton';
import UpArrowIcon from '../../../assets/icons/UpArrowIcon';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 210
        }
    }
};

export interface IFilterCategoryValue {
    key: string;
    name: string;
    callback?: (...args: any) => any;
}

export interface IFilterCategories {
    key?: string;
    name: string;
    radioGroupId?: string;
    radioButtonMode?: boolean;
    defaultOpen?: boolean;
    defaultValue?: string;
    values?: IFilterCategoryValue[];
    callback?: (obj: any, appKey: string) => any;
    operator?: 'OR' | 'AND';
}

export enum EFilterOperator {
    AND = 'AND',
    OR = 'OR'
}

interface IProps {
    categories: IFilterCategories[];
    handleFilterValueChange?: (categories: IFilterCategories[], activeFilters: string[]) => void;
    isDisabled?: boolean;
    emptyQuickFilters?: () => void;
    isQuickFiltersSelected: boolean;
    dropdownMode?: boolean;
    defaultActiveFilters?: string[];
    // filterElements: IUserSkillVM[] | IAllOutcomeDTO[];
}

const Filters: FC<IProps> = ({
    categories,
    handleFilterValueChange,
    isDisabled,
    emptyQuickFilters,
    isQuickFiltersSelected,
    dropdownMode = false,
    defaultActiveFilters
}) => {
    const { t } = useTranslation();
    const { emptyActiveFilters, activeFilters, setActiveFilters } = useFiltersStateValue();
    const defaultFiltersRef = useRef<boolean>(false);

    const filterCategoriesMemo = useMemo(() => {
        if (!categories) return <></>;
        return categories.map((category) => {
            let values = category.values;
            if (!values && category.key && category.name) {
                return <SimpleCheckboxFilter key={category.key} name={category.name} />;
            }
            if (category.values && category.operator === EFilterOperator.OR) {
                return (
                    <StyledFormControl key={category?.key}>
                        <StyledSelect
                            placeholder='Filter'
                            multiple
                            value={activeFilters}
                            // onChange={handleChange}
                            input={<Input disableUnderline />}
                            renderValue={(selected) => {
                                return undefined;
                            }}
                            MenuProps={MenuProps}
                        >
                            {category.values.map((activityFilterMenuItem) => {
                                // const isChecked = selectedFilterValue.findIndex(
                                //     (filterValue) => filterValue === activityFilterMenuItem.name
                                // );
                                const isChecked = Math.random();
                                return (
                                    <StyledMenuItem
                                        sx={{ height: 32, paddingLeft: '4px' }}
                                        key={activityFilterMenuItem.name}
                                        value={activityFilterMenuItem.name}
                                    >
                                        <Checkbox checked={isChecked !== -1} />
                                        <MenuItemTitleSpan>{activityFilterMenuItem.name}</MenuItemTitleSpan>
                                    </StyledMenuItem>
                                );
                            })}
                        </StyledSelect>
                    </StyledFormControl>
                );
            }
            return (
                <FilterCategory
                    key={category.name}
                    radioGroupId={category.radioGroupId}
                    defaultOpen={category.defaultOpen}
                    radioButtonMode={category.radioButtonMode}
                    title={category.name}
                    values={values!}
                    defaultValue={category.defaultValue}
                />
            );
        });
    }, [categories]);

    useEffect(() => {
        if (defaultActiveFilters && defaultActiveFilters.length > 0 && !defaultFiltersRef.current) {
            setActiveFilters(defaultActiveFilters);
            defaultFiltersRef.current = true;
        }
    }, [defaultActiveFilters]);

    // const handleDropdownChange = (event: SelectChangeEvent<unknown>) => {
    //     const newValue = event.target.value as string;
    //     const defaultValueCategory = categories.find((category) => category.defaultValue === newValue);
    //     if (defaultValueCategory && defaultValueCategory.values) {
    //         changeActiveFilter(
    //             newValue,
    //             defaultValueCategory.values.filter((categoryValue) => categoryValue.key !== newValue).map((c) => c.key)
    //         );
    //     } else changeActiveFilter(event.target.value as string);
    // };

    const filterCategoriesDropdownMemo = useMemo(() => {
        if (!categories) return <></>;
        return (
            <FilterDropdownCategoryBox>
                {categories.map((category) => {
                    let values = category.values;
                    if (!values && category.key && category.name) {
                        return <SimpleCheckboxFilter key={category.key} name={category.name} />;
                    }
                    if (category.values && category.operator === EFilterOperator.OR) {
                        const handleChange = (event: SelectChangeEvent<any>) => {
                            setActiveFilters([...new Set([...event.target.value])]);
                        };
                        return (
                            <StyledFormControl
                                key={category.key}
                                style={{ width: '100%', marginRight: 'unset', minWidth: '210px' }}
                            >
                                <StyledSelect
                                    multiple
                                    value={activeFilters}
                                    onChange={handleChange}
                                    // input={<Input disableUnderline placeholder={category.name} />}
                                    renderValue={(selected: unknown) => {
                                        if (selected && (selected as []).length > 0) return 'Active filters';
                                        return category?.name || '';
                                    }}
                                    MenuProps={MenuProps}
                                    displayEmpty
                                    IconComponent={UpArrowIcon}
                                >
                                    {category.values.map((activityFilterMenuItem) => {
                                        const isChecked = activeFilters.find((active) => {
                                            return active === activityFilterMenuItem.key;
                                        });
                                        return (
                                            <StyledMenuItem
                                                sx={{ height: 32, paddingLeft: '4px' }}
                                                key={activityFilterMenuItem.key}
                                                value={activityFilterMenuItem.key}
                                            >
                                                <Checkbox checked={isChecked !== undefined} />
                                                <MenuItemTitleSpan>{activityFilterMenuItem.name}</MenuItemTitleSpan>
                                            </StyledMenuItem>
                                        );
                                    })}
                                </StyledSelect>
                            </StyledFormControl>
                        );
                    }
                    if (category.values) {
                        return (
                            <FilterDropdown
                                key={category.name}
                                category={category}
                                // items={
                                //     category.defaultValue
                                //         ? [
                                //               {
                                //                   value: category.defaultValue,
                                //                   name: category.defaultValue,
                                //               },
                                //               ...dropdownValues,
                                //           ]
                                //         : dropdownValues
                                // }
                                // handleChange={handleDropdownChange}
                                // defaultValue={category.defaultValue}
                            />
                        );
                    }
                    return <div key={category.name}></div>;
                })}
            </FilterDropdownCategoryBox>
        );
    }, [categories, activeFilters]);

    const clearFiltersHandler = () => {
        emptyActiveFilters();
        if (emptyQuickFilters) {
            emptyQuickFilters();
        }
    };

    useEffect(() => {
        if (handleFilterValueChange) {
            handleFilterValueChange(categories, activeFilters);
        }
    }, [activeFilters]);

    return (
        <RootBox isDisabled={!!isDisabled}>
            <StyledFieldset disabled={!!isDisabled}>
                <TitleBox>
                    <TitleSpan>{t('filters.title')}</TitleSpan>
                    {(isQuickFiltersSelected ||
                        !(
                            (activeFilters.length === 1 && activeFilters[0] === 'all') ||
                            activeFilters.length === 0
                        )) && (
                        <TextButton
                            style={{ padding: 0, height: 'unset', minHeight: 'unset', justifyContent: 'flex-end' }}
                            onClick={clearFiltersHandler}
                            mode='primary'
                        >
                            {t('filters.clearAll')}
                        </TextButton>
                    )}
                </TitleBox>
                {dropdownMode ? filterCategoriesDropdownMemo : filterCategoriesMemo}
            </StyledFieldset>
        </RootBox>
    );
};

export default Filters;
