import { FC, useMemo } from 'react';
import {
    ContentBox,
    ContentColumn,
    DetailedInfoBox,
    HeaderBox,
    ItemBox,
    ItemLabelTypography,
    ItemValueTypography,
    OverallInfoBox,
    PrimaryInfoBox,
    PrimaryInfoSubtitle,
    PrimaryInfoTitle,
    PrimaryInfoValue,
    RootBox,
    SecondaryInfoBox,
    SecondaryInfoSubtitle,
    SecondaryInfoTitle,
    SecondaryInfoValue,
    TitleTypography
} from './Style';

interface IProps {
    allocatedNuliaLicenses: number;
    assignedNuliaLicenses: number;
    unassignedNuliaLicenses: number;
    totalOfficeLicenses: number;
    totalOfficeWithNoLicenses: number;
    totalNumberOfResellers: number;
    totalNumberOfCustomers: number;
}

const UsageSummarySimple: FC<IProps> = ({
    allocatedNuliaLicenses,
    assignedNuliaLicenses,
    unassignedNuliaLicenses,
    totalOfficeLicenses,
    totalOfficeWithNoLicenses,
    totalNumberOfCustomers,
    totalNumberOfResellers
}) => {
    const contentMemo = useMemo(() => {
        return (
            <>
                <ContentColumn>
                    <ItemBox>
                        <ItemLabelTypography isAccent>Nulia Licenses Allocated</ItemLabelTypography>
                        <ItemValueTypography isAccent>{allocatedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                    <ItemBox>
                        <ItemLabelTypography>Nulia Licenses Assigned</ItemLabelTypography>
                        <ItemValueTypography>{assignedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                    <ItemBox>
                        <ItemLabelTypography>Nulia Licenses Unassigned</ItemLabelTypography>
                        <ItemValueTypography>{unassignedNuliaLicenses.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                </ContentColumn>
                <ContentColumn>
                    <ItemBox>
                        <ItemLabelTypography>Number of Resellers</ItemLabelTypography>
                        <ItemValueTypography variant='subtitle1'>
                            {totalNumberOfResellers.toLocaleString()}
                        </ItemValueTypography>
                    </ItemBox>
                    <ItemBox>
                        <ItemLabelTypography>Number of Customers</ItemLabelTypography>
                        <ItemValueTypography>{totalNumberOfCustomers.toLocaleString()}</ItemValueTypography>
                    </ItemBox>
                </ContentColumn>
            </>
        );
    }, [
        allocatedNuliaLicenses,
        assignedNuliaLicenses,
        unassignedNuliaLicenses,
        totalNumberOfCustomers,
        totalNumberOfResellers
    ]);

    const overallInfoMemo = useMemo(() => {
        return (
            <OverallInfoBox>
                <SecondaryInfoBox>
                    <SecondaryInfoTitle variant='overline'>Licenses Overview</SecondaryInfoTitle>
                    <SecondaryInfoSubtitle variant='overline'>Total M365 licenses</SecondaryInfoSubtitle>
                    <SecondaryInfoValue variant='h6'>{totalOfficeLicenses.toLocaleString()}</SecondaryInfoValue>
                </SecondaryInfoBox>
                <SecondaryInfoBox>
                    <SecondaryInfoTitle variant='overline'></SecondaryInfoTitle>
                    <SecondaryInfoSubtitle variant='overline'>Allocated Nulia Licenses</SecondaryInfoSubtitle>
                    <SecondaryInfoValue variant='h6'>{allocatedNuliaLicenses.toLocaleString()}</SecondaryInfoValue>
                </SecondaryInfoBox>
                <PrimaryInfoBox>
                    <PrimaryInfoTitle variant='overline'>Opportunity!</PrimaryInfoTitle>
                    <PrimaryInfoSubtitle variant='overline'>M365 Users with no Nulia Licenses</PrimaryInfoSubtitle>
                    <PrimaryInfoValue variant='h6'>{totalOfficeWithNoLicenses.toString()}</PrimaryInfoValue>
                </PrimaryInfoBox>
            </OverallInfoBox>
        );
    }, [totalOfficeLicenses, allocatedNuliaLicenses, totalOfficeWithNoLicenses]);

    return (
        <RootBox>
            <HeaderBox>
                <TitleTypography>License Usage Summary</TitleTypography>
            </HeaderBox>
            <ContentBox>
                {overallInfoMemo}
                <DetailedInfoBox>{contentMemo}</DetailedInfoBox>
            </ContentBox>
        </RootBox>
    );
};

export default UsageSummarySimple;
