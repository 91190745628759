import { ChangeEvent, CSSProperties, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Fuse, { FuseResult } from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Colors';
import SearchIcon from '../../icons/SearchIcon';
import { useTranslation } from 'react-i18next';
import { IAccountVM } from '../../interfaces/views/IAccountVM';
import { EAccountStatus } from '../../interfaces/enums/EAccountStatus';
import { EAccountType } from '../../interfaces/enums/EAccountType';

const useStyles = makeStyles({
    searchAccounts: {
        position: 'relative'
    },
    search: {
        backgroundColor: Colors.white,
        height: 36,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10
    },
    searchIcon: {
        marginRight: 8,
        height: 24,
        width: 24
    },
    input: {
        width: '100%',
        border: 0,
        margin: 0,
        padding: 0,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        color: Colors.textSecondary,
        '&:focus': {
            outline: 'none'
        },
        '&::placeholder': {
            color: Colors.textTertiary
        }
    },
    searchResults: {
        paddingTop: 4,
        paddingBottom: 8,
        backgroundColor: Colors.white,
        borderRadius: 4,
        boxShadow: '0 5px 16px 1px rgba(0,0,0,0.08), 0 2px 8px 0 rgba(0,0,0,0.08)',
        marginTop: 4,
        position: 'absolute',
        zIndex: 111111,
        width: 356,
        marginLeft: 10
    },
    searchResult: {
        zIndex: 111111,
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        height: 40,
        minHeight: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 8,
        paddingRight: 8,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(101,78,163,0.04) !important'
        }
    }
});

type Props = {
    resellerId?: string;
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    resultsStyle?: CSSProperties;
    backgroundColor?: string;
    searchWidth?: number;
    resultsWidth?: number;
};

const SearchAccounts: FC<Props> = ({ resellerId, backgroundColor, searchWidth, resultsWidth }) => {
    const classes = useStyles();
    const [allAccounts, setAllAccounts] = useState<IAccountVM[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const searchRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [showResults, setShowResults] = useState<boolean>(false);
    // const [results, setResults] = useState<string[]>([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [results, setResults] = useState<FuseResult<IAccountVM>[]>([]);

    useEffect(() => {
        setAllAccounts([
            {
                id: '1',
                companyName: 'Hanai Global',
                status: EAccountStatus.REGISTRATION_STARTED,
                type: EAccountType.RESELLER,
                nuliaLicenses: 33,
                officeLicenses: 24
            },
            {
                id: '2',
                companyName: 'Nulia',
                status: EAccountStatus.CONNECTED,
                type: EAccountType.CUSTOMER,
                nuliaLicenses: 21,
                officeLicenses: 55
            },
            {
                id: '3',
                companyName: 'Nulia 2',
                status: EAccountStatus.NOT_CONNECTED,
                type: EAccountType.CUSTOMER,
                nuliaLicenses: 21,
                officeLicenses: 55
            }
        ]);
    }, []);

    const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        const fuse = new Fuse(allAccounts, {
            keys: [`companyName`]
        });
        setResults(fuse.search(value).slice(0, 5));
        setShowResults(value !== '');
        setSearchString(event.target.value);
    };

    const onSelectOption = (fuseResult: FuseResult<IAccountVM>) => (event: MouseEvent) => {
        if (inputRef.current) {
            inputRef.current.value = fuseResult.item.companyName;
        }
        setResults([]);
        setSearchString('');
        const account = allAccounts.find((a) => a.companyName === fuseResult.item.companyName);
        if (account) {
            navigate(`/partner/account/details/${account.id}`);
        }
    };

    return (
        <div className={classes.searchAccounts} style={{ width: searchWidth }} ref={searchRef}>
            <div className={classes.search} style={{ backgroundColor }}>
                <div className={classes.searchIcon}>
                    <SearchIcon large={true} color={Colors.textTertiary} />
                </div>
                <input
                    className={classes.input}
                    style={{ backgroundColor }}
                    value={searchString}
                    onChange={onSearch}
                    placeholder={t('general.searchPlaceholder')}
                    onBlur={() => {
                        setTimeout(() => {
                            setShowResults(false);
                        }, 300);
                    }}
                    ref={inputRef}
                />
            </div>

            {(results.length > 0 ||
                (results.length > 0 && inputRef.current && inputRef.current.value !== '' && showResults)) && (
                <div className={classes.searchResults} style={{ width: resultsWidth }}>
                    {results.map((result, index) => (
                        <>
                            <div
                                className={classes.searchResult}
                                key={result.item.id + index}
                                onClick={onSelectOption(result)}
                                tabIndex={0}
                            >
                                {result.item.companyName}
                            </div>
                        </>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SearchAccounts;
