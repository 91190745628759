import { Switch, Tab, Tabs } from '@mui/material';
import Dialog from '../../components/dialogs/Dialog';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { NTextInput, Tooltip } from 'nulia-ui';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    ContentWrapper,
    CountBox,
    CurrentLicenseNumberLabelTypography,
    CurrentLicenseNumberValueTypography,
    LicenseSyncLabel,
    NewLicenseNumberLabelTypography,
    NewLicenseNumberValueTypography,
    StyledForm,
    StyledSwitchFormControlLabel,
    StyledTextInputFormControlLabel,
    TabPanelRootBox
} from './Style';
import InfoIcon from '../../components/icons/InfoIcon';
import { useTranslation } from 'react-i18next';

interface IProps {
    isOpen: boolean;
    onCloseCallback: () => void;
    currentLicenseNumber?: number;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <TabPanelRootBox>{children}</TabPanelRootBox>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const UpdateLicenseCountModal: FC<IProps> = ({ isOpen, onCloseCallback, currentLicenseNumber }) => {
    const [activeTabValue, setActiveTabValue] = useState<number>(0);
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabValue(newValue);
    };

    const handleConfirm = (event: React.SyntheticEvent) => {
        alert('TODO: Submit');
    };

    const {
        control,
        handleSubmit,
        formState: { isValid, isSubmitting, errors },
        setValue,
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            sync: false,
            numberOfLicenses: 0
        }
    });
    const numberLicensesWatch = useWatch({ name: 'numberOfLicenses', control });

    useEffect(() => {
        setValue('sync', false);
        setValue('numberOfLicenses', 0);
        reset();
    }, [activeTabValue]);

    const handleUpdateLicenseCount = () => {
        alert('ON submit');
    };

    const tabContentMemo = useMemo(() => {
        if (currentLicenseNumber === undefined) return <></>;
        return (
            <StyledForm onSubmit={handleSubmit(handleUpdateLicenseCount)} ref={formRef}>
                {activeTabValue === 0 && (
                    <Controller
                        name='sync'
                        control={control}
                        render={({ field }) => (
                            <StyledSwitchFormControlLabel
                                label={
                                    <LicenseSyncLabel>
                                        License Sync
                                        <Tooltip title={t('updateLicenseCount.licenseSyncTooltip')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </LicenseSyncLabel>
                                }
                                labelPlacement='start'
                                control={
                                    <Switch
                                        color='secondary'
                                        // checked={isActionSuccess}
                                        // onChange={() => {
                                        //     recommendationActionCallback(true);
                                        // }}
                                        aria-label='Switch to Master Level'
                                        inputProps={{ 'aria-label': 'Switch to' }}
                                    />
                                }
                            />
                        )}
                    />
                )}
                <Controller
                    name={'numberOfLicenses'}
                    control={control}
                    rules={{
                        required: true,
                        max: {
                            value: activeTabValue === 0 ? 500000 : currentLicenseNumber!,
                            message:
                                activeTabValue === 0
                                    ? 'Maximum number of licenses is 500000'
                                    : `You can remove maximum of ${currentLicenseNumber} licenses`
                        },
                        min: 0
                    }}
                    render={({ field }) => {
                        return (
                            <StyledTextInputFormControlLabel
                                label='Number of Licenses'
                                labelPlacement='end'
                                control={
                                    <NTextInput
                                        type='number'
                                        label=''
                                        field={field}
                                        value={field.value}
                                        onChange={field.onChange}
                                        errorMessage={errors['numberOfLicenses']?.message as string}
                                        boxStyle={{ flex: 2 }}
                                    />
                                }
                            />
                        );
                    }}
                />
                {
                    <>
                        <CountBox>
                            <CurrentLicenseNumberLabelTypography>
                                Current licence number:
                            </CurrentLicenseNumberLabelTypography>
                            <CurrentLicenseNumberValueTypography>
                                {currentLicenseNumber || 0}
                            </CurrentLicenseNumberValueTypography>
                        </CountBox>
                        <CountBox>
                            <NewLicenseNumberLabelTypography>
                                New licence number after submitting:
                            </NewLicenseNumberLabelTypography>
                            <NewLicenseNumberValueTypography>
                                {activeTabValue == 0 && !isNaN(numberLicensesWatch)
                                    ? //@ts-ignore
                                      (currentLicenseNumber || 0) + parseInt(numberLicensesWatch || 0)
                                    : //@ts-ignore
                                      (currentLicenseNumber || 0) - parseInt(numberLicensesWatch || 0)}
                            </NewLicenseNumberValueTypography>
                        </CountBox>
                    </>
                }
            </StyledForm>
        );
    }, [activeTabValue, errors, isValid, numberLicensesWatch, currentLicenseNumber]);

    return (
        <Dialog
            open={isOpen}
            onClose={onCloseCallback}
            content={
                <ContentWrapper>
                    <Tabs value={activeTabValue} onChange={handleChange}>
                        <Tab label='Add Licenses' {...a11yProps(0)} />
                        <Tab label='Remove Licenses' {...a11yProps(1)} />
                    </Tabs>
                    <CustomTabPanel value={activeTabValue} index={0}>
                        {tabContentMemo}
                    </CustomTabPanel>
                    <CustomTabPanel value={activeTabValue} index={1}>
                        {tabContentMemo}
                    </CustomTabPanel>
                </ContentWrapper>
            }
            acceptButtonWidth={150}
            onAccept={handleConfirm}
            acceptText={activeTabValue === 0 ? 'Add Licenses' : 'Remove Licenses'}
            cancelText='Cancel'
            isSubmitActionDisabled={isSubmitting || !isValid || numberLicensesWatch === 0}
            isBackdropCloseActive={false}
            width={500}
            height={370}
        ></Dialog>
    );
};

export default UpdateLicenseCountModal;
