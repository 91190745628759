import { FC, useEffect, useState } from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { useStore } from '../../store/StoreProvider';
import { useApiService } from '../../services/ApiServiceProvider';
// import UsageSummary from './usage-summary/UsageSummary';
import { CardBox, CustomersBox } from './Style';
import AccountsTable from './table/AccountsTable';
import { useAccountsStateValue } from '../../contexts/AccountsContext';
import UsageSummarySimple from './usage-summary-simple/UsageSummarySimple';

const Accounts: FC = () => {
    const { user, accounts, setAccounts } = useStore();
    const [retrievedData, setRetrievedData] = useState<boolean>(false);
    const ApiService = useApiService();
    // const getAccountData = useGetAccountData();
    const { data } = useAccountsStateValue();

    useEffect(() => {
        let stop = false;
        if (user && !retrievedData && !stop) {
            setRetrievedData(true);
            // getAccountData(user.resellerId);
        }
        return () => {
            stop = true;
        };
    }, [ApiService, accounts, retrievedData, setAccounts, user]);

    // const sections = useMemo(() => {
    //     return [
    //         [
    //             {
    //                 name: 'Direct',
    //                 value: 20,
    //                 color: '#654ea3'
    //             },
    //             {
    //                 name: 'Indirect',
    //                 value: 14,
    //                 color: '#f3f1f8'
    //             },
    //             {
    //                 name: 'Total',
    //                 value: 34,
    //                 isSum: true
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'Direct',
    //                 value: 35,
    //                 color: '#654ea3'
    //             },
    //             {
    //                 name: 'Indirect',
    //                 value: 15,
    //                 color: '#f3f1f8'
    //             },
    //             {
    //                 name: 'Total',
    //                 value: 50,
    //                 isSum: true
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'Direct',
    //                 value: 64,
    //                 color: '#654ea3'
    //             },
    //             {
    //                 name: 'Indirect',
    //                 value: 31,
    //                 color: '#f3f1f8'
    //             },
    //             {
    //                 name: 'Total',
    //                 value: 95,
    //                 isSum: true
    //             }
    //         ],
    //         [
    //             {
    //                 name: 'With license',
    //                 value: 50,
    //                 color: '#654ea3'
    //             },
    //             {
    //                 name: 'Without license',
    //                 value: 32,
    //                 color: '#f3f1f8'
    //             },
    //             {
    //                 name: 'Total',
    //                 value: 82,
    //                 isSum: true
    //             }
    //         ]
    //     ];
    // }, []);

    return (
        <Page>
            <PageContent>
                <CustomersBox>
                    <UsageSummarySimple
                        allocatedNuliaLicenses={1000}
                        assignedNuliaLicenses={600}
                        unassignedNuliaLicenses={400}
                        totalOfficeLicenses={2500}
                        totalOfficeWithNoLicenses={700}
                        totalNumberOfCustomers={5}
                        totalNumberOfResellers={7}
                    />
                    {/* <UsageSummary sections={sections} /> */}
                    <CardBox>
                        <AccountsTable isLoading={false} data={data} />
                    </CardBox>
                </CustomersBox>
            </PageContent>
        </Page>
    );
};

export default Accounts;
