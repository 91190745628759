import { IAccountVM } from '../../../interfaces/views/IAccountVM';
import { Dropdown, ITableHeadCell, Table, Tooltip, makeAccountDetailsRoute } from 'nulia-ui';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from 'react-feather';
import UpdateLicenseCountModal from '../../../components/modals/UpdateLicenseCountModal';
import { AddRemoveLicenceIconBox, CircleSpan, FilterBox, FilterTypography } from './Style';
import { SelectChangeEvent } from '@mui/material';
import { EAccountStatus } from '../../../interfaces/enums/EAccountStatus';
import { EAccountType } from '../../../interfaces/enums/EAccountType';
import { useNavigate } from 'react-router-dom';

interface ITableHeader {
    status: string;
    companyName: string;
    officeLicenses: string;
    nuliaLicenses: string;
    addRemoveLicenses: any;
}

interface IProps {
    data?: IAccountVM[];
    isLoading: boolean;
}

const AccountsTable: FC<IProps> = ({ data, isLoading }) => {
    const { t } = useTranslation();
    const [isUpdateLicenseCountModalOpen, setUpdateLicenseCountModalOpen] = useState<boolean>(false);
    const [accountTypeFilter, setAccountTypeFilter] = useState<EAccountType | 'All Accounts'>('All Accounts');
    const [accountStatusFilter, setAccountStatusFilter] = useState<EAccountStatus | 'All Statuses'>('All Statuses');
    const [filteredData, setFilteredData] = useState<IAccountVM[] | undefined>();
    const currentNumberOfLicensesForOpenModal = useRef<number | undefined>(undefined);
    const navigate = useNavigate();

    const onOpenUpdateLicenseModalClick = async (
        e: React.MouseEvent | React.KeyboardEvent,
        id: string,
        numberOfNuliaLicenses?: number
    ) => {
        e.stopPropagation();
        currentNumberOfLicensesForOpenModal.current = numberOfNuliaLicenses;
        setUpdateLicenseCountModalOpen(true);
    };

    const onCloseCallback = () => {
        setUpdateLicenseCountModalOpen(false);
    };

    useEffect(() => {
        let partnerAccounts = data;
        if (accountTypeFilter === EAccountType.CUSTOMER) {
            partnerAccounts = data?.filter((account) => account.type === EAccountType.CUSTOMER);
        } else if (accountTypeFilter === EAccountType.RESELLER) {
            partnerAccounts = data?.filter((account) => account.type === EAccountType.RESELLER);
        } else {
            partnerAccounts = data;
        }

        if (accountStatusFilter === EAccountStatus.NOT_CONNECTED) {
            partnerAccounts = partnerAccounts?.filter((account) => account.status === EAccountStatus.NOT_CONNECTED);
        } else if (accountStatusFilter === EAccountStatus.REGISTRATION_STARTED) {
            partnerAccounts = partnerAccounts?.filter(
                (account) => account.status === EAccountStatus.REGISTRATION_STARTED
            );
        } else if (accountStatusFilter === EAccountStatus.CONNECTED) {
            partnerAccounts = partnerAccounts?.filter((account) => account.status === EAccountStatus.CONNECTED);
        }

        setFilteredData(partnerAccounts);
    }, [data, accountStatusFilter, accountTypeFilter]);

    const headCells: ITableHeadCell<ITableHeader>[] = [
        {
            id: 'status',
            label: 'Status',
            customRender(value, row) {
                switch (value) {
                    case EAccountStatus.CONNECTED:
                        return <CircleSpan color='#B0CB3E' />;
                    case EAccountStatus.REGISTRATION_STARTED:
                        return <CircleSpan color='rgba(0, 0, 0, 0.08)' />;
                    case EAccountStatus.NOT_CONNECTED:
                        return <CircleSpan color='#FFF' />;
                }
                return <></>;
            }
        },
        {
            id: 'companyName',
            label: 'Company Name'
        },
        {
            id: 'officeLicenses',
            label: 'Office 365 Licenses'
        },
        {
            id: 'nuliaLicenses',
            label: 'Nulia Licenses'
        },
        {
            id: 'addRemoveLicenses',
            label: '',
            customRender(value, row) {
                return (
                    <AddRemoveLicenceIconBox
                        tabIndex={0}
                        onClick={(e) => onOpenUpdateLicenseModalClick(e, row.id, row?.nuliaLicenses)}
                    >
                        <Tooltip title={t('partnerTooltips.openUpdateLicenseModal')}>
                            <Edit />
                        </Tooltip>
                    </AddRemoveLicenceIconBox>
                );
            }
        }
    ];

    const onChangeAccountTypeFilter = (event: SelectChangeEvent<unknown>) =>
        setAccountTypeFilter(event.target.value as EAccountType);

    const onChangeAccountStatusFilter = (event: SelectChangeEvent<unknown>) =>
        setAccountStatusFilter(event.target.value as EAccountStatus);

    const onAccountTableRowClick = useCallback(() => {
        navigate(makeAccountDetailsRoute(1));
    }, []);

    return (
        <div>
            <FilterBox>
                <FilterTypography variant='overline'>FILTER BY</FilterTypography>
                <Dropdown
                    items={[
                        { value: 'All Accounts', name: 'All Accounts' },
                        { value: EAccountType.CUSTOMER, name: 'Customers' },
                        { value: EAccountType.RESELLER, name: 'Resellers' }
                    ]}
                    value={accountTypeFilter}
                    handleChange={onChangeAccountTypeFilter}
                    defaultValue={accountTypeFilter}
                />
                <Dropdown
                    items={[
                        { value: 'All Statuses', name: 'All Statuses' },
                        { value: EAccountStatus.NOT_CONNECTED, name: 'Not Connected' },
                        { value: EAccountStatus.REGISTRATION_STARTED, name: 'Registration Started' },
                        { value: EAccountStatus.CONNECTED, name: 'Connected' }
                    ]}
                    value={accountStatusFilter}
                    handleChange={onChangeAccountStatusFilter}
                    defaultValue={accountStatusFilter}
                />
            </FilterBox>
            <Table
                headCells={headCells}
                isLoading={isLoading}
                data={filteredData}
                propertyKeys={headCells.map((headCell) => headCell.id)}
                onRowClick={onAccountTableRowClick}
            />
            <UpdateLicenseCountModal
                currentLicenseNumber={currentNumberOfLicensesForOpenModal.current}
                isOpen={isUpdateLicenseCountModalOpen}
                onCloseCallback={onCloseCallback}
            />
        </div>
    );
};

export default AccountsTable;
