import { Typography } from '@mui/material';
import { styled } from '@mui/system';

export const RootBox = styled('div')(() => ({
    height: '100vh'
}));

export const ResourcesInnerBox = styled('div')(({ theme }) => ({
    padding: 24,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)'
}));

export const ResourceTitle = styled(Typography)({
    fontFamily: 'Ubuntu',
    fontWeight: 600,
    fontSize: 32,
    letterSpacing: 0.25,
    lineHeight: '40px',
    marginBottom: 24,
    marginTop: 16
});

export const ResourceItemBox = styled('div')({
    border: '1px solid rgba(101,78,163,0.12)',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
    padding: 24,
    display: 'flex',
    justifyContent: 'space-between'
});

export const ResourceName = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: 16,
    letterSpacing: 0.5,
    lineHeight: '20px'
});
