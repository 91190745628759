import { makeStyles } from '@material-ui/core';
import { FC, useState, ChangeEvent, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Colors';
import { PrimaryButton } from 'nulia-ui';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    contact: {
        height: '100vh',
        width: 'calc(100vw - 200px)',
        maxWidth: '100%'
    },
    contactContainer: {
        padding: 24,
        borderRadius: 4,
        backgroundColor: Colors.white,
        boxShadow: '0 1px 4px 0.5px rgba(0,0,0,0.08), 0 0.5px 1.5px 0 rgba(0,0,0,0.08)'
    },
    bottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 16
    },
    thanks: {
        color: Colors.textPrimary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        marginBottom: 24
    },
    thanksContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        color: Colors.textPrimary,
        fontFamily: 'Ubuntu',
        fontWeight: 600,
        fontSize: 32,
        letterSpacing: 0.25,
        lineHeight: '40px',
        marginBottom: 24,
        marginTop: 16
    },
    textArea: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 16,
        letterSpacing: 0.5,
        lineHeight: '24px',
        border: 0,
        height: 200,
        width: '100%',
        resize: 'none',
        outline: 'none',
        caretColor: 'rgba(101,78,163,0.88)'
    }
});

const Contact: FC = () => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [body, setBody] = useState<string>('');
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => setBody(event.target.value);
    const submit = (event: MouseEvent) => {
        event.preventDefault();
        setIsSubmitted(true);
    };
    const goBack = () => navigate(-1);

    return (
        <Page>
            <PageContent>
                <div className={classes.contact}>
                    {!isSubmitted ? (
                        <div className={classes.contactContainer}>
                            <textarea
                                className={classes.textArea}
                                placeholder={t('contact.textfieldInputPlaceholder')}
                                onChange={onChange}
                                value={body}
                            />
                            <div className={classes.bottom}>
                                <PrimaryButton
                                    type='submit'
                                    clickHandler={submit}
                                    style={{ width: 124 }}
                                    disabled={body === ''}
                                    title={t('contact.submitButton')}
                                ></PrimaryButton>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.thanksContainer}>
                            <div className={classes.thanks}>{t('contact.feedbackSubmitted')}</div>
                            <PrimaryButton clickHandler={goBack} style={{ width: 100, margin: 'auto' }}>
                                {t('contact.goBackButton')}
                            </PrimaryButton>
                        </div>
                    )}
                </div>
            </PageContent>
        </Page>
    );
};

export default Contact;
