import { FC, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { RESOURCES_PATH } from '../../utils/constants';
import { ResellerType, Resource } from '../../types/types';
import VideoDialog from '../../components/dialogs/VideoDialog';
import { Colors } from '../../Colors';
import { PrimaryButton } from 'nulia-ui';
import { useApiService } from '../../services/ApiServiceProvider';
import { useStore } from '../../store/StoreProvider';
import { ResourceItemBox, ResourceName, ResourcesInnerBox, RootBox } from './Style';

const useStyles = makeStyles({
    description: {
        color: Colors.textSecondary,
        fontFamily: 'Open Sans',
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: '20px',
        marginTop: 8
    },
    buttons: {
        width: 116,
        marginLeft: 24
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    badge: {
        backgroundColor: Colors.textPrimary,
        borderRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 56,
        marginRight: 8
    },
    badgeText: {
        fontSize: 12,
        fontFamily: 'Ubuntu',
        color: Colors.white
    }
});

const Resources: FC = () => {
    const classes = useStyles();
    const { user, setShowWarning, setWarningData } = useStore();
    const [openVideo, setOpenVideo] = useState<boolean>(false);
    const [videoSrc, setVideoSrc] = useState<string>('');
    const [resources, setResources] = useState<Resource[] | undefined>(undefined);
    const [receivedData, setReceivedData] = useState<boolean>(false);
    const ApiService = useApiService();

    useEffect(() => {
        if (!user) {
            return;
        }
        const getResources = async () => {
            const response = await ApiService.getResources(user.resellerType === ResellerType.Starter);
            if (!response) {
                return;
            }
            setResources(response);
        };

        if (!receivedData) {
            setReceivedData(true);
            getResources();
        }
    }, [user, receivedData, setWarningData, setShowWarning, ApiService]);

    const LoadingResource: FC<{ inner?: boolean }> = ({ inner }) => (
        <Box marginBottom={inner ? 2 : undefined} style={{ maxWidth: '800px', width: '100%', minWidth: '500px' }}>
            <Skeleton variant='rect' height={118} width='100%' />
        </Box>
    );

    const onClick = (resource: Resource) => (event: unknown) => {
        if (resource.type === 'pdf') {
            window.open(`${RESOURCES_PATH}${resource.url}`, '_blank');
        } else {
            setVideoSrc(`${RESOURCES_PATH}${resource.url}`);
            setOpenVideo(true);
        }
    };
    const closeVideo = () => setOpenVideo(false);

    return (
        <Page>
            <PageContent>
                <RootBox>
                    <ResourcesInnerBox>
                        {resources ? (
                            <>
                                {resources.map((resource, index) => (
                                    <ResourceItemBox key={`${resource.title}${index}`}>
                                        <div>
                                            {resource.starter ? (
                                                <div className={classes.titleWrapper}>
                                                    <div className={classes.badge}>
                                                        <div className={classes.badgeText}>{'strings?.starter'}</div>
                                                    </div>
                                                    <ResourceName>{resource.title}</ResourceName>
                                                </div>
                                            ) : (
                                                <ResourceName>{resource.title}</ResourceName>
                                            )}
                                            <div className={classes.description}>{resource.description}</div>
                                        </div>
                                        <div className={classes.buttons}>
                                            <PrimaryButton clickHandler={onClick(resource)} style={{ width: 120 }}>
                                                {'strings?.view'}
                                            </PrimaryButton>
                                        </div>
                                    </ResourceItemBox>
                                ))}
                            </>
                        ) : (
                            <>
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource inner={true} />
                                <LoadingResource />
                            </>
                        )}
                    </ResourcesInnerBox>
                </RootBox>
                <VideoDialog open={openVideo} onClose={closeVideo} src={videoSrc} />
            </PageContent>
        </Page>
    );
};

export default Resources;
