import { makeStyles } from '@material-ui/core';
import { FC } from 'react';

const useStyles = makeStyles({
    pageContent: {
        height: '100vh',
        paddingTop: 10,
        paddingLeft: 0
    },
    content: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 24,
        width: 1080,
        paddingLeft: '20px',
        paddingRight: '20px'
        // [theme.breakpoints.down('lg')]: {
        //     width: '100%',
        //     flexDirection: 'column'
        // }
    }
});

const PageContent: FC<React.PropsWithChildren> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.pageContent}>
            <div className={classes.content}>{children}</div>
        </div>
    );
};

export default PageContent;
