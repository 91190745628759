import { IResourceDTO } from 'interfaces/dtos/IResourceDTO';
import { IAccountDTO } from '../interfaces/dtos/IAccountDTO';
import { IAccountVM } from '../interfaces/views/IAccountVM';
import { IResourceVM } from '../interfaces/views/IResourceVM';
import { EAccountStatus } from '../interfaces/enums/EAccountStatus';
import { EAccountType } from '../interfaces/enums/EAccountType';

export const mapAccounts: (accountDTOList: IAccountDTO[]) => IAccountVM[] = (accountDTOList) => {
    return accountDTOList.map((accountDTO: IAccountDTO) => {
        return {
            ...accountDTO,
            status: accountDTO.status as EAccountStatus,
            type: accountDTO.type as EAccountType
        };
    });
};

export const mapResources: (resourceDTOList: IResourceDTO[]) => IResourceVM[] = (resourceDTOList) => {
    return resourceDTOList.map((resourceDTO: IResourceDTO) => {
        return {
            ...resourceDTO
        };
    });
};
