import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getTokenAndHeaders, IAuthParams, useAuthParams } from 'nulia-ui';
import { EApiQueryKey } from '../types/enums/EApiQueryKey';
import { apiInstance } from './util/apiInstance';
import { IAccountVM } from '../interfaces/views/IAccountVM';
import { mapAccounts } from './mappers';
import { IAccountDetailsVM } from 'interfaces/views/IAccountDetailsVM';

// GET ACCOUNTS
export const getAccounts = async (authParams: IAuthParams) => {
    const { headers } = await getTokenAndHeaders(authParams);
    const { data } = await apiInstance.get(`accounts`, headers);
    return mapAccounts(data);
};

export const useGetAccountsQuery: () => UseQueryResult<IAccountVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_FETCH_ALL],
        queryFn: async () => {
            return getAccounts(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};

// GET ACCOUNT DETAILS
export const getAccountDetails = async (authParams: IAuthParams) => {
    // const { headers } = await getTokenAndHeaders(authParams);
    // const { data } = await apiInstance.get(`accounts`, headers);
    return [];
};

export const useGetAccountDetailsQuery: () => UseQueryResult<IAccountDetailsVM[], Error> = () => {
    const authParams = useAuthParams();
    return useQuery({
        queryKey: [EApiQueryKey.ACCOUNTS_DETAILS_FETCH],
        queryFn: async () => {
            return getAccountDetails(authParams);
        },
        refetchOnWindowFocus: false,
        enabled: false
    });
};
